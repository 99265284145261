<header>
  <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5">
    <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
      <a [routerLink]="['/']" class="flex items-center">
        <img src="/assets/images/logo.png" class="mr-3 h-6 sm:h-9" alt="iPORTO" />
      </a>
      <div class="flex items-center lg:order-2">
        <a href="{{environment.app_url}}" class="text-white bg-fuchsia-700 hover:bg-fuchsia-800 focus:ring-4 focus:ring-fuchsia-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none">Acessar</a>
        <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mobile-menu-2" aria-expanded="false">
          <span class="sr-only">Abrir menu</span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
          <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
      <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
        <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
          <li>
            <button (click)="displayOverMenuProducts()" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-amber-700 lg:p-0">Produtos</button>
          </li>
          <li>
            <a [routerLink]="['/features']" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-amber-700 lg:p-0">Recursos</a>
          </li>
          <!-- TODO: "Nossa plataforma"
          <li>
            <a [routerLink]="['/platform']" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-amber-700 lg:p-0">Nossa plataforma</a>
          </li>
          -->
          <li>
            <a [routerLink]="['/help']" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-amber-700 lg:p-0">Ajuda</a>
          </li>
        </ul>

        <p-dialog header="Header" [(visible)]="overMenuProducts" [modal]="true" [style]="{ width: '95vw' }" [position]="'top'" [draggable]="false" [resizable]="false">
          <ng-template pTemplate="header">
            <div class="flex flex-col">
              <div class="inline-flex align-items-center justify-content-center gap-2">
                <img src="/assets/images/logo.png" class="h-7" alt="iPORTO" />
                <h2 class="mb-4 text-2xl tracking-tight font-extrabold text-gray-900">Softwares feitos para Times como o seu</h2>
              </div>
              <div class="text-gray-500 sm:text-lg">Aqui na iPORTO nos concentramos em mercados onde a tecnologia, a inovação e o capital podem desbloquear valor a longo prazo e impulsionar o crescimento econômico.</div>
            </div>
          </ng-template>

          <div class="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
            <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">
              <div>
                <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-8 lg:w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="#4f46e5" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                  </svg>
                </div>
                <a (click)="displayOverMenuProducts()" [routerLink]="['/pricing/marketing']" class="text-left">
                  <h3 class="mb-2 text-xl font-bold">Marketing e Automação</h3>
                  <p class="text-gray-500">Dê asas à sua criatividade com nosso E-mail Marketing e Automação. Transforme suas ideias em campanhas envolventes e deixe a automação cuidar do resto enquanto você e sua equipe brilham no mundo do marketing digital.</p>
                </a>
              </div>
              <div>
                <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-8 lg:w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      fill="#4f46e5"
                      d="M128 0C110.3 0 96 14.3 96 32V224h96V192c0-35.3 28.7-64 64-64H480V32c0-17.7-14.3-32-32-32H128zM256 160c-17.7 0-32 14.3-32 32v32h96c35.3 0 64 28.7 64 64V416H576c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H256zm240 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zM64 256c-17.7 0-32 14.3-32 32v13L187.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L352 301V288c0-17.7-14.3-32-32-32H64zm288 84.8L216 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L32 340.8V480c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V340.8z"
                    />
                  </svg>
                </div>
                <a (click)="displayOverMenuProducts()" [routerLink]="['/pricing/smtp']" class="text-left">
                  <h3 class="mb-2 text-xl font-bold">SMTP</h3>
                  <p class="text-gray-500">Abra as portas da comunicação instantânea com nosso SMTP Transacional. Como um mensageiro ágil e confiável, ele entrega suas mensagens com a velocidade do relâmpago, conectando você ao seu público no exato momento em que importa.</p>
                </a>
              </div>
              <div>
                <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-8 lg:w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      fill="#4f46e5"
                      d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM96 212.8c0-20.3 16.5-36.8 36.8-36.8H152c8.8 0 16 7.2 16 16s-7.2 16-16 16H132.8c-2.7 0-4.8 2.2-4.8 4.8c0 1.6 .8 3.1 2.2 4l29.4 19.6c10.3 6.8 16.4 18.3 16.4 30.7c0 20.3-16.5 36.8-36.8 36.8H112c-8.8 0-16-7.2-16-16s7.2-16 16-16h27.2c2.7 0 4.8-2.2 4.8-4.8c0-1.6-.8-3.1-2.2-4l-29.4-19.6C102.2 236.7 96 225.2 96 212.8zM372.8 176H392c8.8 0 16 7.2 16 16s-7.2 16-16 16H372.8c-2.7 0-4.8 2.2-4.8 4.8c0 1.6 .8 3.1 2.2 4l29.4 19.6c10.2 6.8 16.4 18.3 16.4 30.7c0 20.3-16.5 36.8-36.8 36.8H352c-8.8 0-16-7.2-16-16s7.2-16 16-16h27.2c2.7 0 4.8-2.2 4.8-4.8c0-1.6-.8-3.1-2.2-4l-29.4-19.6c-10.2-6.8-16.4-18.3-16.4-30.7c0-20.3 16.5-36.8 36.8-36.8zm-152 6.4L256 229.3l35.2-46.9c4.1-5.5 11.3-7.8 17.9-5.6s10.9 8.3 10.9 15.2v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V240l-19.2 25.6c-3 4-7.8 6.4-12.8 6.4s-9.8-2.4-12.8-6.4L224 240v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-6.9 4.4-13 10.9-15.2s13.7 .1 17.9 5.6z"
                    />
                  </svg>
                </div>
                <a (click)="displayOverMenuProducts()" [routerLink]="['/pricing/sms']" class="text-left">
                  <h3 class="mb-2 text-xl font-bold">SMS</h3>
                  <p class="text-gray-500">Conquiste corações em apenas 160 caracteres! Nosso serviço de SMS é como uma poção mágica para sua comunicação. Envie mensagens que fazem seus clientes sorrir, agir e se sentirem especiais, tudo isso com um toque no botão de enviar.</p>
                </a>
              </div>
              <div>
                <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-8 lg:w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      fill="#4f46e5"
                      d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"
                    />
                  </svg>
                </div>
                <a (click)="displayOverMenuProducts()" [routerLink]="['/pricing/validador-de-email']" class="text-left">
                  <h3 class="mb-2 text-xl font-bold">Validador de E-mail</h3>
                  <p class="text-gray-500">Dê adeus aos e-mails perdidos no limbo virtual! Com nosso Validador de E-mail, suas listas de contatos são como diamantes lapidados - puras, precisas e brilhantes. Garanta que suas mensagens alcancem as caixas de entrada certas, sempre.</p>
                </a>
              </div>
              <!-- TODO: Adicionar novamente "Encurtador de Link"
              <div>
                <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-8 lg:w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      fill="#4f46e5"
                      d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96-31.9-25C430.9 239.6 420.1 175.1 377 132c-52.2-52.3-134.5-56.2-191.3-11.7L38.8 5.1zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5L239 162zM116.6 187.9L60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8-50.6-39.9zM220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3L220.9 270z"
                    />
                  </svg>
                </div>
                <a (click)="displayOverMenuProducts()" [routerLink]="['/pricing/encurtador-de-link']" class="text-left">
                  <h3 class="mb-2 text-xl font-bold">Encurtador de Link</h3>
                  <p class="text-gray-500">Encurte o caminho para o sucesso! Nosso Encurtador de Link é como uma varinha mágica para URLs longos e complicados. Transforme-os em links elegantes e atrativos, prontos para encantar seus clientes e impulsionar suas taxas de conversão.</p>
                </a>
              </div>
              -->
              <!-- TODO: Adicionar novamente "NPS"
              <div>
                <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-8 lg:w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      fill="#4f46e5"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm156.4 25.6c-5.3 7.1-15.3 8.5-22.4 3.2s-8.5-15.3-3.2-22.4c30.4-40.5 91.2-40.5 121.6 0c5.3 7.1 3.9 17.1-3.2 22.4s-17.1 3.9-22.4-3.2c-17.6-23.5-52.8-23.5-70.4 0z"
                    />
                  </svg>
                </div>
                <a (click)="displayOverMenuProducts()" [routerLink]="['/pricing/nps']" class="text-left">
                  <h3 class="mb-2 text-xl font-bold">NPS</h3>
                  <p class="text-gray-500">Transforme opiniões em oportunidades com nossa plataforma de NPS. Deixe que as vozes dos seus clientes guiem o caminho para o sucesso. Com insights valiosos ao seu alcance, você estará sempre um passo à frente na jornada do cliente.</p>
                </a>
              </div>
              -->
            </div>
          </div>
        </p-dialog>
      </div>
    </div>
  </nav>
</header>
