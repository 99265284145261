<app-www-header>
  <!-- Menu Header -->
</app-www-header>

<router-outlet>
  <!-- Page content -->
</router-outlet>

<app-www-footer>
  <!-- Footer -->
</app-www-footer>
