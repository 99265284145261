import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
import { DateService } from '~services/date.service';
// ]
@Injectable()
export class CollectiveCoreApplicationFaqService {
  authenticated_user: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService, public dateService: DateService) {}

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      if (typeof data[entry] != 'object') {
        querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
      }
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getData(data: any) {
    return this.getPost(data);
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};

    if (data instanceof FormData) {
      data.forEach((value, key, formData) => {
        postData[key] = this.getPostParseData(value);
      });

      return postData;
    } else {
      for (let key in data) {
        postData[key] = this.getPostParseData(data[key]);
      }

      return postData;
    }
  }

  /**
   *
   * @param data
   */
  getPostFormData(data: any) {
    return data;
  }

  /**
   *
   * @param value
   */
  getPostParseData(value: any) {
    if (Array.isArray(value)) {
      return { ...value };
    } else {
      if (typeof value == 'object') {
        if (value == null) {
          return null;
        } else if (Object.prototype.toString.call(value) === '[object Date]') {
          return this.dateService.convertDatePickerTimeToMySQLTime(value);
        } else if (Object.prototype.toString.call(value) === '[object File]') {
          return value;
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/core/application/faq?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/core/application/faq/' + data.id + '?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }
}
