import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// Portuguese
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);
// Geral
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgModule, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT, Inject } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// PrimeNg
import { MessageService, ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
// App
import { QRCodeModule } from 'angularx-qrcode';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Especificações da Aplicação
import { ErrorService } from 'src/project/services/error.service';
import { CacheService } from 'src/project/services/cache.service';
import { DateService } from '~services/date.service';
import { SEO } from '~project/shared/services/SEO/service';
import { enableProdMode } from '@angular/core';
// [Collective/Core/Appplication]
// [Collective/Application/Faq]
import { CollectiveCoreApplicationFaqService } from '~project/services/collective/core/application/faq/faq.service';
enableProdMode();

// [[Layout]]
import {
  // [[Modulo de Www]]
  LayoutWwwComponent,
  WwwHeaderComponent,
  WwwFooterComponent,
  // []
} from '../project/layout';
// []

@NgModule({
  declarations: [
    AppComponent,
    // [[Modulo de Www]]
    LayoutWwwComponent,
    WwwHeaderComponent,
    WwwFooterComponent,
    // []
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    // PrimeNg
    TooltipModule,
    ToastModule,
    OverlayPanelModule,
    DialogModule,
  ],
  providers: [
    ErrorService,
    // PrimeNg
    MessageService,

    ConfirmationService,
    CacheService,
    DateService,
    SEO,
    // [Collective/Core/Appplication]
    // [Collective/Application/Faq]
    CollectiveCoreApplicationFaqService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document) {}
}
