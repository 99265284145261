import { environment } from 'src/environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
// [[Providers]]

// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-www-footer',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
})
export class WwwFooterComponent implements OnDestroy {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;
  per_page: number = 10;
  page: number = 1;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private readonly viewport: ViewportScroller
  ) {}

  currentRoute: string = '';
  ngOnInit(): void {
    this.ipBuildAppDependencies();
  }

  /**
   * build App dependencies
   */
  ipBuildAppDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * on destroy
   */
  ngOnDestroy() {}

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }
}
