import { environment } from 'src/environments/environment';
import { Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { DateService } from '~services/date.service';
// ]
@Injectable()
export class ErrorService {
  constructor(public http: HttpClient, public dateService: DateService) {}

  /**
   *
   * @param data
   */
  getData(data: any) {
    return this.getPost(data);
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};

    if (data instanceof FormData) {
      data.forEach((value, key, formData) => {
        postData[key] = this.getPostParseData(value);
      });

      return postData;
    } else {
      for (let key in data) {
        postData[key] = this.getPostParseData(data[key]);
      }

      return postData;
    }
  }

  /**
   *
   * @param data
   */
  getPostFormData(data: any) {
    return data;
  }

  /**
   *
   * @param value
   */
  getPostParseData(value: any) {
    if (Array.isArray(value)) {
      return { ...value };
    } else {
      if (typeof value == 'object') {
        if (value == null) {
          return null;
        } else if (Object.prototype.toString.call(value) === '[object Date]') {
          return this.dateService.convertDatePickerTimeToMySQLTime(value);
        } else if (Object.prototype.toString.call(value) === '[object File]') {
          return value;
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }

  /**
   *
   * @param err
   */
  throw(originalHttpError: any) {
    let errors = [];

    if (originalHttpError.error.errors) {
      for (let error in originalHttpError.error.errors) {
        errors.push(originalHttpError.error.errors[error]);
      }
    } else {
      if (originalHttpError.status == 500) {
        errors.push('Infelizmente a aplicação respondeu de forma inesperada. Por favor, tente novamente. Se o problema persistir contate nosso suporte.');
      }
      if (originalHttpError.status == 404) {
        errors.push('Os recursos dessa página não foram localizados.');
      }
    }

    var finally_error = {
      status: originalHttpError.status,
      url: originalHttpError.url,
      messageServer: originalHttpError.message,
      message: originalHttpError.error.message,
      errors: errors,
    };

    this.postThrow(finally_error);

    return finally_error;
  }

  /**
   * Posts throw
   * @param data
   */
  postThrow(data: any) {
    let postData = this.getPost(data);
    this.http
      .post(environment.http_garbage_collection, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .subscribe(
        (response: any) => {},
        (error) => {}
      );
  }
}
